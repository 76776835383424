import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { setDisplayName } from 'recompose';
import { NavLink } from 'react-router-dom';

import Loader_ from 'rambler-ui/Loader';
import { Button, Input, Checkbox, Field } from 'components/forms';
import { Form } from 'components/ui';
import { typography, ruiClass } from 'theme/mixins';

const Wrapper = styled.div`
  ${Button} {
    width: 125px;
  }

  ${Field} {
    padding-bottom: 33px;

    input {
      border: 1px solid #d1d5db !important;
      height: 50px !important;
    }

    label {
      color: #4a515c;
      padding-bottom: 11px;
      display: inline-block;
      text-transform: uppercase;
      ${typography(12, 15, 700)};
    }
  }
`;

const Loader = styled(Loader_)`
  ${ruiClass('rui-Loader-loader')} {
    min-height: auto;
  }
`;

const Title = styled.div`
  color: #9cb4ca;
  text-align: center;
  margin-bottom: 59px;
  ${typography(40, 48, 700)};
`;

const ForgetWrapper = styled.div`
  position: relative;
`;

const Forget = styled(NavLink)`
  top: 0;
  right: 0;
  color: #3c6f9d;
  position: absolute;
  ${typography(14, 16)};
  text-decoration: none;
`;

const Submit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RememberField = styled(Field)`
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-bottom: 0 !important;
  margin-left: 40px;

  label {
    color: #4a515c;
    font-size: 14px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    padding-bottom: 0 !important;
  }
`;

@setDisplayName('LoginForm')
@inject('loginState')
@observer
class LoginForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    loginState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { loginState, ...rest } = this.props;

    const handleChange = (key, e) => {
      const newValue = e.target.value.replace(/\s+/g, '');
      loginState.$(key).set(newValue);
    };

    console.log(loginState.$('email').validated);

    return (
      <Wrapper {...rest}>
        <Loader>
          <Title>Вход</Title>

          <Form form={loginState}>
            <Field
              onChange={(e) => handleChange('email', e)}
              error={loginState.$('email').error}
              field={loginState.$('email')}
              component={Input}
            />

            <ForgetWrapper>
              <Forget to='/forget'>Забыли пароль?</Forget>
            </ForgetWrapper>

            <Field
              onChange={(e) => handleChange('password', e)}
              error={loginState.error || loginState.$('password').error}
              field={loginState.$('password')}
              component={Input}
            />

            <Submit>
              <Button size='large' type='submit'>
                Войти
              </Button>

              <RememberField field={loginState.$('remember')} component={Checkbox} />
            </Submit>
          </Form>
        </Loader>
      </Wrapper>
    );
  }
}

export default styled(LoginForm)``;
