import { useEffect, useState } from 'react';

import instance from 'connection/instance';

const useCompanyInfo = (value) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerError, setRegisterError] = useState(false);

  const register = async (values) => {
    if (registerLoading) return;

    try {
      setRegisterError(false);
      setRegisterLoading(true);

      await instance.post('/api/users', { data: values });
      return true;
    } catch (e) {
      setRegisterError(true);
      throw e;
    } finally {
      setRegisterLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setError(null);
        setLoading(true);
        const {
          data: { data }
        } = await instance.get(`/api/filters/organizations?data[inn]=${value}`);

        setData(data);
      } catch (e) {
        setError(e?.response?.data?.messages?.[0]);
      } finally {
        setLoading(false);
      }
    };

    if (value && (value?.length === 10 || value?.length === 12)) {
      fetch();
    }
  }, [value]);

  return [
    { data, loading, error, registerLoading, registerError },
    { register, setRegisterError }
  ];
};

export default useCompanyInfo;
