import { types } from 'mobx-state-tree';

import { Address } from '../Address';
import { Image } from '../Image';
import { TreatmentFactor } from '../TreatmentFactor';
import { PriceFormation } from 'stores/models';
import { Specialization } from 'stores/HotelsStore/Specialization';
import { Region } from 'stores/HotelsStore/Region';

export const HotelItem = types.model('Hotel', {
  id: types.identifier,
  slug: types.string,
  address: Address,
  name: types.string,
  rating: types.number,
  star: types.number,
  price_formation: PriceFormation,
  description: types.string,
  email: types.maybeNull(types.string),
  images: types.optional(types.array(Image), []),
  region: types.maybeNull(Region),
  specializations: types.array(Specialization),
  treatment_factors: types.array(TreatmentFactor),
  tariff: types.maybeNull(types.frozen())
});
