function calcReservationsPrice({ slots }) {
  const price = slots.reduce((sum, slot) => sum + slot.price, 0);
  const net_price = slots.reduce((sum, slot) => sum + slot.net_price, 0);
  const gross_price = slots.reduce((sum, slot) => sum + slot.gross_price, 0);
  const discount = slots.reduce((sum, slot) => sum + slot.gross_price - slot.net_price, 0);
  const discountPercent = (discount / gross_price) * 100;
  const discount_in_percent = Number.isInteger(discountPercent)
    ? discountPercent.toString()
    : discountPercent.toFixed(2).replace(/\.?0+$/, '');

  return { price, net_price, gross_price, discount, discount_in_percent };
}

export default calcReservationsPrice;
