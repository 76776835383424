import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Label = styled.label`
  user-select: none;
`;

const Input = styled.input`
  border: 1px solid ${props => props.hasError ? '#E26464' : '#D9D9D9'};
`;

const ErrorMessage = styled.div`
  color: #E26464;
  font-size: 14px;
  line-height: 16px;
`;

@observer
class Field extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    field: PropTypes.object,
    name: PropTypes.string,
    error: PropTypes.any,
    onBlur: PropTypes.any,
    onChange: PropTypes.any
  };

  static defaultProps = {
    className: '',
    component: Input
  };

  render() {
    const {
      className, field, component, error, onBlur, onChange, ...rest
    } = this.props;

    if (field) {
      const Component = component;

      return (
        <Wrapper className={className}>
          {field.label && (
            <Label htmlFor={field.id}>
              {field.label}
            </Label>
          )}
          <Component
            {...rest}
            field={field}
            onBlur={onBlur}
            onChange={onChange}
            status={error ? 'error' : null}
          />
          {error && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
        </Wrapper>
      );
    }

    return null;
  }
}

export default styled(Field)``;
